export function setCookie(name, value, days = 365 * 2) {
  const date = new Date();
  date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
  const domain = window.location.host;
  const expires = `; path=/; domain=.${domain}; expires=${date.toGMTString()}`;
  document.cookie = `${name}=${value}${expires}`;
}

export function getCookie(name) {
  return document.cookie.split(';').reduce((acc, cur) => {
    const [key, val] = cur.split('=');
    acc[key.trim()] = val.trim();
    return acc;
  }, {})[name];
}
  