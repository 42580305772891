import select from 'dom-select';

export const FormWrap = (el) => {
  const ui = {
    el,
    toggle: select('.form-wrap__toggle', el),
    body: select('body', document),
    inputs: select.all('.input__input', el),
    selects: select.all('.select select', el),
    fieldsets: select.all('.fieldset', el),
    submitBtn: select.all('[type="submit"]', el),
  };

  const toggleFieldsets = () => {
    ui.fieldsets.forEach((fs) => {
      if (fs.hasAttribute("disabled")) {
        fs.removeAttribute("disabled");
        fs.removeAttribute("aria-disabled");
      } else {
        fs.setAttribute("disabled", "");
        fs.setAttribute("aria-disabled", "true");
      }
    });
  }

  const addSpinner = () => {
    let spinnerContainer = document.createElement("div");
    let spinnerElement = document.createElement("div");

    spinnerContainer.classList.add("spinner-container");
    spinnerElement.classList.add("spinner");

    ui.el.append(spinnerContainer);
    spinnerContainer.append(spinnerElement);
  }

  const disableSubmitBtn  = () => {
    if (ui.submitBtn) {
      ui.submitBtn.forEach((btn) => {
        btn.setAttribute("disabled", "true");
        btn.setAttribute("aria-disabled", "true");
      });
    }
  }

  const submitForm = (event) => {
    if (window.isTurnStorybook) {
      event.preventDefault();
    };

    disableSubmitBtn();
    addSpinner();
  }

  const addEvents = () => {
    if (ui.toggle) {
      ui.toggle.addEventListener("click", () => {
        toggleFieldsets();
      });
    }
    
    ui.el.addEventListener("submit", (event) => {
      submitForm(event);
    });
  }

  const init = () => {
    addEvents();
  };

  init();
};

export default FormWrap;
