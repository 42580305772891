// DOM-specific JS should be done here
// see /boilerplate/src/js/browser.js for the import pattern

import select from 'dom-select';
import { serialize } from 'dom-form-serializer/dist/dom-form-serializer';

export const CardDeck = (el) => {
  const ui = {
    el,
    body: select('body', document),
    toggle: select('.card-deck__toggle'),
    close: select('.product-filters__close'),
    apply: select('.product-filters__apply'),
    reset: select('.product-filters__reset'),
    relevance: select('.js-relevance-desc'),
    priceAsc: select('.js-price-asc'),
    priceDesc: select('.js-price-desc'),
    alphaAsc: select('.js-alpha-asc'),
    alphaDesc: select('.js-alpha-desc'),
    sortingLinks: select.all('.link--sort'),

    form: select('#js-filters-form', el),
    filters: select.all('.product-filters .input__input', el),
    filterGroups: select.all('[id$="-filters"]', el),
    cards: select.all('.card', el),
    cardsParent: select('.card-deck__children', el),
    count: select('#js-result-count span', el),
    sortingContainer: select('.card-deck__sorting', el),
  };

  let inputClass = ".input__input";


  // optional messaging and data options that might come from CMS
  ui.nopeMsg = ui.el && ui.el.dataset.nullMessage ? ui.el.dataset.nullMessage : 'There are no results matching your choices.';
  ui.resetMsg = ui.el && ui.el.dataset.resetMsg ? ui.el.dataset.resetMsg : 'Clear Filters';
  ui.dataSep = ui.el && ui.el.dataset.separator ? ui.el.dataset.separator : '|';

  const handleToggle = () => {
    ui.el.classList.toggle('filters-active');
    ui.body.classList.toggle('nav-open');
  };

  // deselect all filters
  const resetFilters = (ev) => {
    ev.preventDefault();

    ui.filters.forEach((filter) => {
      filter.checked = false;
      filter.indeterminate = false;
    });

    syncUrlQuerystring(false);
    getCardCount();
  };

  const getCardCount = () => {
    const cardCount = document.querySelectorAll('.card-deck__children .card:not(.filtered--hide)').length
    ui.count.innerText = cardCount;
    return;
  }

  const showCard = (card) => {
    card.classList.add('filtered--show');
    card.classList.remove('filtered--hide');
    return card;
  };

  const hideCard = (card) => {
    card.classList.remove('filtered--show');
    card.classList.add('filtered--hide');
    return card;
  };

  const hideAllCards = () => {
    ui.cards.forEach((card) => {
      hideCard(card);
    });
    getCardCount();
  };

  const deactivateSortingLinks = () => {
    ui.sortingLinks.forEach((link) => {
      link.classList.remove('active');
    });
  }

  // sorting
    // alphabetical
    const sortAlphaAsc = (a, b) => {
      if (a.dataset.heading < b.dataset.heading) return -1;
      if (a.dataset.heading > b.dataset.heading) return 1;
      return 0;
    }
    const sortAlphaDesc = (a, b) => {
      if (a.dataset.heading < b.dataset.heading) return 1;
      if (a.dataset.heading > b.dataset.heading) return -1;
      return 0;
    }
    const sortDataAlphaAsc = () => {
      deactivateSortingLinks();
      ui.alphaAsc.classList.add('active');
      let sorted = ui.cards.sort(sortAlphaAsc);
      sorted.forEach((e) => ui.cardsParent.appendChild(e) );
    }
    const sortDataAlphaDesc = () => {
      deactivateSortingLinks();
      ui.alphaDesc.classList.add('active');
      let sorted = ui.cards.sort(sortAlphaDesc);
      sorted.forEach( (e) => ui.cardsParent.appendChild(e) );
    }

    // relevance
    const sortRelevanceDesc = (a, b) => {
      if (a.dataset.relevance < b.dataset.relevance) return 1;
      if (a.dataset.relevance > b.dataset.relevance) return -1;
      return 0;
    }
    const sortDataRelevanceDesc = () => {
      deactivateSortingLinks();
      ui.relevance.classList.add('active');
      let sorted = ui.cards.sort(sortRelevanceDesc);
      sorted.forEach( (e) => ui.cardsParent.appendChild(e) );
    }

    // price
    const sortPriceAsc = (a, b) => {
      if (a.dataset.price < b.dataset.price) return -1;
      if (a.dataset.price > b.dataset.price) return 1;
      return 0;
    }
    const sortPriceDesc = (a, b) => {
      if (a.dataset.price < b.dataset.price) return 1;
      if (a.dataset.price > b.dataset.price) return -1;
      return 0;
    }

    const sortDataPriceAsc = () => {
      deactivateSortingLinks();
      ui.priceAsc.classList.add('active');
      let sorted = ui.cards.sort(sortPriceAsc);
      sorted.forEach( (e) => ui.cardsParent.appendChild(e) );
    }
    const sortDataPriceDesc = () => {
      deactivateSortingLinks();
      ui.priceDesc.classList.add('active');
      let sorted = ui.cards.sort(sortPriceDesc);
      sorted.forEach( (e) => ui.cardsParent.appendChild(e) );
    }
  // end sorting

  const showAllCards = () => {
    ui.cards.forEach((card) => {
      showCard(card);
    });

    getCardCount();
  };

  // remove no results message
  const notSorry = () => {
    const nope = select('.card-deck__message', el);

    if (nope) {
      ui.cardsParent.removeChild(nope);
    }
  };

  const handleReset = (ev) => {
    resetFilters(ev);
    showAllCards();
    notSorry();
    getCardCount();
  };

  ui.reset.addEventListener('click', handleReset);

  const setFiltersByQuerystring = () => {
    const querystring = new URLSearchParams(document.location.search.substring(1));
    const filterParam = querystring.get("filter");

    if (!filterParam) {
      return;
    }

    const filterset = filterParam.split(ui.dataSep);

    filterset.forEach(value => {
      // worst.filter.logic.ever
      ui.filters.forEach((filter) => {
        if(filter.id === value){
          filter.checked = true;
          handleChildChange(filter);
        }
      });

      // filters are set, now do something about it
      handleChange();
    });
  };

  const syncUrlQuerystring = (filterKeysArray) => {
    //Parse url, get querystring
    const querystring = new URLSearchParams(document.location.search.substring(1));
    if (filterKeysArray && filterKeysArray.length > 0)
      querystring.set("filter", filterKeysArray.join(ui.dataSep));
    else
      querystring.delete("filter");
    //live! update the url querystring
    window.history.replaceState({}, '', `${window.location.pathname}?${querystring}`);
  };

  // build no results message and append to cards container
  const saySorry = () => {
    const wrap = document.createElement('div');
    const nope = document.createElement('h3');
    const msg = document.createTextNode(ui.nopeMsg);
    const reset = document.createElement('button');
    const resetMsg = document.createTextNode(ui.resetMsg);

    wrap.classList.add('card-deck__message');
    wrap.setAttribute('style', 'grid-column: 1 / end');

    nope.classList.add('card--nope', 'heading', 'heading--h3');
    nope.appendChild(msg);

    reset.classList.add('button');
    reset.appendChild(resetMsg);

    wrap.appendChild(nope);
    wrap.appendChild(reset);

    notSorry();
    ui.cardsParent.appendChild(wrap);
    reset.addEventListener('click', handleReset);
  };

  // convert snake_case and kabob-case to camelCase
  const snakeToCamel = (str) => str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );

  // converts filter name to data attribute
  const idToDataset = (id) => {
    const name = id.split('.')[0];

    return snakeToCamel(name);
  };

  // filters an object to only items that aren't null
  const trueObj = (obj) => {
    return Object.keys(obj).reduce((acc, cv) => {
      if (obj[cv]) {
        acc[cv] = obj[cv]; // eslint-disable-line
      }

      return acc;
    }, {});
  };

  // create object of arrays of inputs by group
  const filterGroupsInputObj = () => {
    const output = {};

    // loop over groups, find inputs in group,
    // push to array in output object
    ui.filterGroups.forEach((group) => {
      const id = group.id.split('-filters')[0];
      const inputs = select.all(inputClass, group);

      return output[id] = inputs;
    });

    return output;
  };

  // sort selected inputs by existing filter groups
  const groupedSelectedinputs = (groupObj, groupArr, allSelected) => {
    // loop groups
    const filteredGroup = groupArr.map((group) => {
      // get inner array of inputs
      const innerArr = groupObj[group];

      // find selected inputs among groups
      const filteredSel = innerArr.filter((item) => allSelected.includes(item.id));
      return filteredSel;
    });

    // get rid of empty arrays
    const selectedGroups = filteredGroup.filter((fg) => fg.length > 0);
    return selectedGroups;
  };

  const handleChildChange = (el) => {
    const container = el.closest('.checkbox-group');
    const checkboxes = select.all('.input__input', container);

    // selecting the parent input is a two part process:
    const parentContainer = el.closest('.checkbox-group--parent');
    const parentInput = select('[data-is-toggle]', parentContainer);

    const totalCheckboxes = checkboxes.length;
    const checkedCount = checkboxes.filter(cb => cb.checked).length;

    if (parentInput) {
      if (checkedCount === 0) {
        parentInput.checked = false;
        parentInput.indeterminate = false;
      } else if (checkedCount === totalCheckboxes) {
        parentInput.checked = true;
        parentInput.indeterminate = false;
      } else {
        parentInput.checked = false;
        parentInput.indeterminate = true;
      }
    }
  };

  const toggleAllChildren = (el) => {
    const container = el.closest('.checkbox-group');
    const checkboxes = select.all('.input__input', container);

    if (el.checked) {
      checkboxes.forEach(cb => cb.checked = true);
    } else {
      checkboxes.forEach(cb => cb.checked = false);
    }
  };

  // where the actual filtering happens
  const handleChange = () => {
    const filterObject = serialize(ui.form); // { name: value }

    const selectedFilters = trueObj(filterObject);

    // if nothing's selected, reset & bail
    if (Object.keys(selectedFilters).length === 0 && selectedFilters.constructor === Object) {
      notSorry();
      showAllCards();
      syncUrlQuerystring(false);
      // console.log('nothing selected, check your card data-attributes and filter values');
      return;
    }

    const filterKeysArray = Object.keys(selectedFilters); // array of selected names

    const groupedInputs = filterGroupsInputObj();

    const groupArr = Object.keys(groupedInputs);

    const selectedGroups = groupedSelectedinputs(groupedInputs, groupArr, filterKeysArray);

    // ensure the querystring is *nSync
    syncUrlQuerystring(filterKeysArray);

    // create array to push cards into
    const groupCards = [];

    // hide all cards
    // then loop over them and see which match selected filters by group
    ui.cards
      .map((card) => hideCard(card)).forEach((card) => {
        // loop over selected inputs by group
        // selectedGroups is an array of array
        for (let index = 0; index < selectedGroups.length; index++) {
          // if this group's results array doesn't yet exist, create it
          if (groupCards[index] == null) {
            groupCards[index] = [];
          }

          // get this group's array of selected inputs...
          const innerSelected = selectedGroups[index];

          // ...and loop over it
          innerSelected.forEach((input) => {
            // create a data attribute based off input
            const dataAttrName = idToDataset(input.id);
            // retrieve card value based on data attribute
            const cardValue = card.dataset[dataAttrName] ? card.dataset[dataAttrName].toLowerCase() : '';
            // allow for multiple values on a single card
            // per filter (eg, data-language="spanish|english")
            const valueArr = cardValue.split(ui.dataSep);
            // test if values match, ie card is in selected filter
            const yepnope = valueArr.filter((value) => value === input.value);

            // if card matches, push it into this group's array
            if (yepnope.length > 0) {
              groupCards[index].push(card);
            }
          });
        }
      });

      // find cards common to all group arrays
      const commonCards = groupCards.shift().filter((group) => {
        return groupCards.every((card) => {
          return card.indexOf(group) !== -1;
        })
      });

      // ensure we have matching cards and show them
      if (commonCards.length > 0) {
        notSorry();
        commonCards.map((card) => showCard(card));
        // console.log(commonCards);
      } else {
        hideAllCards();
        saySorry();
      }

      // console.log(commonCards.length);
      getCardCount();
    };

  // if we're not filterable, bail early
  if (!ui.filters) {
    return;
  }

  // Process the querystring and set filter checkboxes onLoad
  setFiltersByQuerystring();

  // bind filter change event
  ui.filters.forEach((filter) => {
    filter.addEventListener('change', (ev) => {
      if (filter.getAttribute("data-is-toggle")) {
        toggleAllChildren(filter);
      } else {
        handleChildChange(filter);
      }
      handleChange(ev);
    });
  });

  const addEvents = () => {
    ui.toggle.addEventListener('click', (e) => {
      e.preventDefault();
      handleToggle();
    });
    ui.close.addEventListener('click', (e) => {
      e.preventDefault();
      handleToggle();
    });
    ui.apply.addEventListener('click', () => {
      handleToggle();
    });

    // sorting
    ui.relevance && ui.relevance.addEventListener('click', (e) => {
      e.preventDefault();
      sortDataRelevanceDesc();
    });
    ui.priceAsc.addEventListener('click', (e) => {
      e.preventDefault();
      sortDataPriceAsc();
    });
    ui.priceDesc.addEventListener('click', (e) => {
      e.preventDefault();
      sortDataPriceDesc();
    });
    ui.alphaAsc.addEventListener('click', (e) => {
      e.preventDefault();
      sortDataAlphaAsc();
    });
    ui.alphaDesc.addEventListener('click', (e) => {
      e.preventDefault();
      sortDataAlphaDesc();
    });
  };

  const init = () => {
    addEvents();
    getCardCount();
  };

  init();
};

export default CardDeck;
