import Accordion from '../stories/components/accordion-tabs/accordion-tabs.init';
import CardDeck from '../stories/components/card-deck/card-deck.init';
import CarouselImage from '../stories/blocks/image/image.init';
import CookieConsent from '../stories/components/cookie-consent/cookie-consent.init';
import Expandable from '../stories/components/expandable/expandable.init';
import FormWrap from '../stories/components/form/form.init';
import GlobalAlert from '../stories/components/global-alert/global-alert.init';
import GlobalHeader from '../stories/components/global-header/global-header.init';
// import Input from '../stories/blocks/input/input.init';
import menuToggle from '../stories/blocks/menu-toggle/menu-toggle.init';
import NavItem from '../stories/blocks/nav-item/nav-item.init';
import Splide from '../stories/components/splide/splide.init';

import domReady from './lib/domReady';
import initModule from './lib/initModule';
import addIeBodyClass from './lib/detectIE11';

// Intialize scripts here requiring DOM access.
//
// Any modules imported here should export a function
// that takes a node as its only parameter.
// Import the module then initialize it below inside
// domScripts(), calling initModule() and passing in
// the function and a selector that aligns with the element
// you want to pass into the module.
// initModule() calls the method on each instance
// of the selector passed, so your script can assume
// it is working on a unique DOM node.
//
// example:
// import coolThing from '../ui/blocks/CoolThing/cool-thing.init';
// initModule(coolThing, '.cool-thing');

const domScripts = () => {
  initModule(Accordion, '.accordion-tabs');
  initModule(addIeBodyClass, 'body');
  initModule(CardDeck, '.card-deck--product-list');
  initModule(CarouselImage, '#main-carousel .splide__slide .image');
  initModule(CookieConsent, '.cookie-consent');
  initModule(Expandable, '.expandable');
  initModule(FormWrap, '.form-wrap');
  initModule(GlobalAlert, '.global-alert');
  // initModule(Input, '.input__input');
  initModule(menuToggle, '.menu-toggle');
  initModule(NavItem, 'button.nav-item');
  initModule(GlobalHeader, '.global-header');
  initModule(Splide, '.splide');
};

// domReady ensures our scripts fire inside Storybook
// even when navigating component to component,
// calling the passed function on DOMContentLoaded
// and each time the page changes, using MutationObserver
domReady(domScripts);
