// DOM-specific JS should be done here
// see /boilerplate/src/js/browser.js for the import pattern

import select from 'dom-select';

export const CarouselImage = (el) => {
  const ui = {
    el,
    body: select('body', document),
    mainCarousel: select('#main-carousel', document),
    navCarousel: select('#navigation-carousel', document),
    overlay: select('.splide__overlay', document),
  };

  const handleToggle = () => {
    ui.body.classList.toggle('is-locked');
    ui.mainCarousel.classList.toggle('is-locked');
    ui.navCarousel.classList.toggle('is-locked');
    ui.el.classList.toggle('is-zoomed');
  };

  const handleClose = () => {
    ui.body.classList.remove('is-locked');
    ui.mainCarousel.classList.remove('is-locked');
    ui.navCarousel.classList.remove('is-locked');
    ui.el.classList.remove('is-zoomed');
  }

  const addEvents = () => {
    ui.el.addEventListener('click', () => {
      handleToggle();
    });

    if (ui.overlay) {
      ui.overlay.addEventListener('click', () => {
        handleClose();
      });
    }
  }

  const init = () => {
    addEvents();
  };

  init();
};

export default CarouselImage;
