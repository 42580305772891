// DOM-specific JS should be done here
// see /boilerplate/src/js/browser.js for the import pattern

import select from 'dom-select';

export const Expandable = (el) => {
  const ui = {
    el,
    body: select('body', document),
    toggle: select('.expandable__toggle', el),
    content: select('.expandable__content', el),
  };

  // console.log(ui);

  const handleToggle = () => {
    if (ui.toggle.getAttribute('aria-expanded') === 'true') {
      ui.toggle.setAttribute('aria-expanded', 'false');
      ui.content.setAttribute('aria-hidden', 'true');
    } else {
      ui.toggle.setAttribute('aria-expanded', 'true');
      ui.content.setAttribute('aria-hidden', 'false');
    }    
    return false;
  };

  const addEvents = () => {
    ui.toggle.addEventListener('click', (e) => {
      e.preventDefault();
      handleToggle();
    });
  }

  const init = () => {
    addEvents();
  };

  init();
};

export default Expandable;
