import Splide from '@splidejs/splide';
// import { Video } from '@splidejs/splide-extension-video/';
// console.log(Splide);

export const Splider = (el) => {
  const addEvents = () => {
    const main = new Splide('#main-carousel', 
      {
        arrows: true,
        pagination: false,
        rewind: true,
        type: 'fade',
      }
    );

    const thumbnails = new Splide('#navigation-carousel', 
      {
        arrows: true,
        gap: '.75rem',
        fixedWidth: '7rem',
        isNavigation: true,
        pagination: false,
        rewind: true,
        type: 'slide',
        
        breakpoints: {
          768: {
            gap: '.75rem',
            fixedWidth: '6rem',
          },
        },
      }
    );

    try {
      main.sync(thumbnails);
    } catch {};

    main.mount(Splide.Video);
    thumbnails.mount();
  }

  const init = () => {
    addEvents();
  };

  init();
};

export default Splider;
