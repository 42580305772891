import select from 'dom-select';
import { setCookie } from '../../../js/lib/cookies';

const COOKIE_NAME = 'to_cc';
const ACCEPTED = '89';

export const CookieConsent = (el) => {
  const ui = {
    el,
    closeBtn: select('#close-cookie-consent', el)
  };

  const { id } = ui.el;

  ui.toggle = document.querySelector(`#opens--${id}`);

  const addEvents = () => {
    ui.closeBtn.addEventListener('click', () => {
      ui.el.classList.add('cookie-consent--is-dismissed');
      setCookie(COOKIE_NAME, ACCEPTED, 30);
    });
  };

  const init = () => {
    addEvents();
  };

  init();
};

export default CookieConsent;
