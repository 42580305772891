// DOM-specific JS should be done here
// see /boilerplate/src/js/browser.js for the import pattern

import select from 'dom-select';
import { setCookie } from '../../../js/lib/cookies';

const cookieName = 'to_ga';

export const GlobalAlert = (el) => {
  const ui = {
    el,
    close: select('.global-alert__close', el),
    header: select('.global-header', document),
    pageShellMain: select('.pageshell__main', document),
  };
  
  const handleClose = () => {
    let alertTimestamp = ui.el.getAttribute('data-alert-updated');
    setCookie(cookieName, alertTimestamp, 30);
    ui.el.removeAttribute('aria-expanded');
    ui.el.setAttribute('aria-hidden', 'true');
    ui.header.classList.remove('global-header--has-cta');
  };

  const addEvents = () => {
    if ( ui.close ) {
      ui.close.addEventListener('click', () => {
        handleClose();
      });
    }
  }

  const init = () => {
    addEvents();
  };

  init();
};

export default GlobalAlert;
