// DOM-specific JS should be done here
// see /boilerplate/src/js/browser.js for the import pattern

import select from 'dom-select';

export const NavItem = (el) => {
  const ui = {
    el,
    body: select('body', document),
    main: select('#main', document),
    toggles: select.all('button.nav-item', document),
    backdrop: select('.global-nav__backdrop'),
  };

  // console.log(ui);

  const closeAllSubnavs = () => {
    ui.toggles.forEach((toggle) => {
      toggle.removeAttribute('aria-expanded');
      ui.body.classList.remove('subnav-open');
    });
  }

  const openSubNav = () => {
    ui.body.classList.remove('subnav-open');
    ui.el.setAttribute('aria-expanded', 'true');
    ui.body.classList.add('subnav-open');
  }

  const addEvents = () => {
    ui.el.addEventListener('click', () => {
      let checkExpanded = ui.el.getAttribute("aria-expanded");
      if (checkExpanded) {
        closeAllSubnavs();
      } else {
        closeAllSubnavs();
        openSubNav();
      }
    });

    try {
    ui.backdrop.addEventListener('click', () => {
      closeAllSubnavs();
    });
    } 
    catch {
      console.log('$main not found');
    }

  }

  const init = () => {
    addEvents();
  };

  init();
};

export default NavItem;
